import { ref } from 'vue'
import { useResizeObserver } from '@vueuse/core'

export function usePageHeaderUi(emit: any) {
  const INITIAL_HEADER_HEIGHT = ref<number>(0) // used only for fake header overlay which prevents page jumps on transition

  const isSticky = ref<boolean>(false)
  const headerHeight = ref<number>(0) // dynamic header height

  const isHeaderSticky = (document: Document) => {
    const isHeaderSticky
      = Math.round(document.documentElement.scrollTop)
      > INITIAL_HEADER_HEIGHT.value
    // emit event only if value changed
    if (isSticky.value !== isHeaderSticky) {
      isSticky.value = isHeaderSticky
      emit('change-sticky', isSticky.value)
    }
  }

  const init = (headerEl: HTMLElement) => {
    isHeaderSticky(document)
    document.addEventListener(
      'scroll',
      event => isHeaderSticky(event.target as Document),
      {
        capture: false,
        passive: true,
      },
    )

    // initial header height
    headerHeight.value = headerEl?.offsetHeight ?? 0
    INITIAL_HEADER_HEIGHT.value = headerHeight.value
    emit('change-height', headerHeight.value)

    useResizeObserver(document.documentElement, () => {
      const height = headerEl?.offsetHeight ?? 1

      INITIAL_HEADER_HEIGHT.value = Math.round(
        isSticky.value ? height + 1 : height,
      )
      if (headerHeight.value !== height) {
        headerHeight.value = headerEl?.offsetHeight ?? 0
        emit('change-height', height)
      }
    })
    useResizeObserver(headerEl, (entries) => {
      const entry = entries[0]
      const { height } = entry.contentRect

      if (headerHeight.value !== height) {
        headerHeight.value = height
        INITIAL_HEADER_HEIGHT.value = Math.round(
          isSticky.value ? height + 1 : height,
        )
        emit('change-height', height)
      }
    })
  }

  return {
    init,
    isSticky,
    headerHeight,
    isHeaderSticky,
    INITIAL_HEADER_HEIGHT,
  }
}
